module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"Fourvill","start_url":"/","background_color":"#171a22","theme_color":"#171a22","display":"minimal-ui","icon":"static/og/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9e560d0c7551a2ecbeec132cccd02e66"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Storage, Collaboration and Publishing tools for Media and Text Content","titleTemplate":"Fourvill | %s","description":"Storage, collaboration and workflow features for audio, video, and image. Workplace for Images, Videos, Podcasts, Youtube videos, Marketing videos, Graphic designs, Music and so on.","openGraph":{"type":"website","url":"https://fourvill.com","title":"Storage, Collaboration and Publishing tools for Media and Text Content","description":"Storage, collaboration and workflow features for audio, video, and image. Workplace for Images, Videos, Podcasts, Youtube videos, Marketing videos, Graphic designs, Music and so on.","images":[{"url":"static/og/image.jpg","width":800,"height":600,"alt":"Fourvill logo"},{"url":"static/og/image@2x.jpg","width":900,"height":800,"alt":"Fourvill logo"},{"url":"static/og/image@2x.jpg"}],"site_name":"Fourvill"},"twitter":{"handle":"@fourvill","site":"fourvill.com","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-163370348-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
